.wrapper {
    display: flex;
    width: 100%;
    flex-direction: column;
    margin-bottom: 24px;
}

.labelBox {
    display: flex;
    justify-content: space-between;
    padding: 0 6px;
    margin-bottom: 4px;
}

.label {
    font-family: 'Inter', serif;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    color: #102A44;
}

.error {
    font-family: 'Inter', serif;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    color: #DF2929;
}
