

.inputBox {
    position: relative;
    display: flex;
}

.inputBox input {
    width: 100%;
    background: #FFFFFF;
    border: 1px solid #C2C2C2;
    border-radius: 6px;
    font-family: 'Inter', serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
}
.inputBox input::placeholder {
    color: #C2C2C2;
}

.inputBox input:focus::placeholder {
    color: transparent;
}

.inputBox input:focus {
    border-color: #009EE2;
}

.input{
    padding: 13px 16px;
}

.adornment {
    position: absolute;
    top: 10px;
    right: 20px;
    width: 18px;
    height: 18px;
    cursor: pointer;
}
.inputPaddingAdornment {
    padding: 13px 40px 13px 16px;
}
