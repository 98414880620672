.css {
    width: 100%;
    height: 42px;
    outline: unset;
    background: linear-gradient(265.87deg, #009EE2 2.29%, #82C1FF 28.85%, #009EE2 51.59%),
    linear-gradient(0deg, #102A44, #102A44);
    box-shadow: 0 4px 10px rgba(0, 158, 226, 0.3);
    border: unset;
    border-radius: 6px;
    font-family: 'Inter', serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    text-align: center;
    color: #FFFFFF;
    cursor: pointer;
}

.css:hover,
.css:focus {
    box-shadow: unset;
    background: linear-gradient(265.87deg, #009EE2 51.77%, #82C1FF 75.5%, #009EE2 92.32%),
    linear-gradient(0deg, #102A44, #102A44);
}

.css:active {
    box-shadow: unset;
    background: linear-gradient(0deg, rgba(71, 71, 71, 0.25), rgba(71, 71, 71, 0.25)),
    linear-gradient(265.87deg, #009EE2 51.77%, #82C1FF 75.5%, #009EE2 92.32%),
    linear-gradient(0deg, #102A44, #102A44);
}
