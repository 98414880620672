* {
    box-sizing: border-box;
}

body, #root {
    width: 100%;
    height: 100vh;
}
button {
    outline: unset;
    border: unset;
    cursor: pointer;
}
p, h1, h2, h3, h4, h5, h5 {
    margin: unset;
    padding: unset;
}
input {
    outline: unset;
}