.main {
    height: 100%;
    width: 100%;
    overflow: auto;
}

.wrapper {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: space-between;
    align-items: center;
}

.formBox {
    width: 320px;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
}

.logo {
    width: 120px;
    height: auto;
    margin-bottom: 52px;
}

.sliderBox {
    width: 797px;
    overflow: hidden;
    height: 100vh;
}

@media (max-width: 1200px) {
    .main {
        background: rgb(212, 0, 117);
        background: linear-gradient(28deg, rgba(212, 0, 117, 0.3) 1%, rgba(255, 255, 255, -7) 26%, rgba(255, 255, 255, 1) 77%, rgba(71, 200, 255, 0.4430147058823529) 100%)
    }

    .wrapper {
        align-items: flex-start;
        justify-content: center;
    }

    .formBox {
        padding: 60px 0;
    }

    .sliderBox {
        display: none;
    }
}