.css {
    cursor: pointer;
    text-decoration: none;
    font-family: 'Inter', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #598CBF;
}

.css:hover {
    text-decoration: underline;
}
.css:visited {
    color: #598CBF;
}