@font-face {
    font-family: "Intro";
    src: local("Intro"), url("./fonts/Intro/Intro.ttf") format('truetype');
}

@font-face {
    font-family: "Inter";
    src: local("Inter"), url("./fonts/Inter/Inter-Light.ttf") format('truetype');
    font-style: normal;
    font-weight: 300;
}

@font-face {
font-family: "Inter";
src: local("Inter"), url("./fonts/Inter/Inter-Regular.ttf") format('truetype');
font-style: normal;
font-weight: 400;
}

@font-face {
font-family: "Inter";
src: local("Inter"), url("./fonts/Inter/Inter-Medium.ttf") format('truetype');
font-style: normal;
font-weight: 500;
}

@font-face {
font-family: "Inter";
src: local("Inter"), url("./fonts/Inter/Inter-SemiBold.ttf") format('truetype');
font-style: normal;
font-weight: 600;
}

@font-face {
    font-family: "Inter";
    src: local("Inter"), url("./fonts/Inter/Inter-Bold.ttf") format('truetype');
    font-style: normal;
    font-weight: 700;
}

@font-face {
    font-family: "Icon";
    src: local("Icon"), url("./fonts/icomoon.ttf") format('truetype');
}

.MuiInputLabel-root.Mui-focused {
    color: #102A44;
}

.icon-bitcoin_symbol:before {
    content: "\e901";
    color: #A1A1A1;
}

.icon-eth_symbol:before {
    content: "\e902";
    color: #A1A1A1;
}

.icon-ever_symbol:before {
    content: "\e903";
    color: #A1A1A1;
}

.icon-eupi_symbol:before {
    content: "\e900";
    color: #A1A1A1;
}

.b24-widget-button-position-bottom-right {
    right: 20px!important;
    bottom: 20px!important;
}